import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonItem, IonLabel, IonSelect, IonPopover } from '@ionic/react';
import './About.scss';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import AboutPopover from '../components/AboutPopover';
import ShowMore from 'react-show-more';

interface AboutProps { }

const About: React.FC<AboutProps> = () => {

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState();
  const [location, setLocation] = useState<'madison' | 'austin' | 'chicago' | 'seattle'>('madison');
  const [conferenceDate, setConferenceDate] = useState('2019-10-06T00:00:00-05:00');

  const selectOptions = {
    header: 'Select a Location'
  };

  const presentPopover = (e: React.MouseEvent) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  // momentjs would be a better way to do this https://momentjs.com/
  function displayDate(date: string, format: string) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const d = new Date(date);
    const year = d.getFullYear();

    if (format === 'y') {
      return year;
    } else {
      const month = monthNames[d.getMonth()];
      const day = d.getDate();

      return month + ' ' + day + ', ' + year;
    }
  }

  const collapsableWelcome = (
    <span>
      <p className="ion-padding-start ion-padding-end">
        Por primera vez, aunando ideas y colaboraciones, realizaremos conjuntamente seis congresos en un solo evento. Este hecho demuestra la vocación de los genetistas latinoamericanos por la integración y el crecimiento conjunto para el avance de la Genética en nuestros países. Contamos con el importante apoyo de la Sociedad Brasilera de Genética y de la International Genetics Federation.
      </p>
      <p className="ion-padding-start ion-padding-end">
        El lema del Congreso será «La arquitectura del genoma: su expresión en los fenotipos y las poblaciones» con el propósito de reflexionar sobre la dinámica de la estructura del genoma y la diversidad de su expresión en los ambientes. El programa incluirá conferencias plenarias, simposios simultáneos y secciones de comunicaciones libres en genética microbiana, vegetal, animal y humana.
      </p>
    </span>
  );

  return (
    <IonPage id="about-page">
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={presentPopover}>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <div className="about-header">
          {/* Instead of loading an image each time the select changes, use opacity to transition them */}
          <div className="about-image madison" style={{'opacity': location === 'madison' ? '1' : undefined}}></div>
          <div className="about-image austin" style={{'opacity': location === 'austin' ? '1' : undefined}}></div>
          <div className="about-image chicago" style={{'opacity': location === 'chicago' ? '1' : undefined}}></div>
          <div className="about-image seattle" style={{'opacity': location === 'seattle' ? '1' : undefined}}></div>
        </div>
        <div className="about-info">
          <h3 className="ion-padding-top ion-padding-start">Congreso ALAG 2019</h3>

          <p className="ion-padding-start ion-padding-end">
            <ShowMore
                  lines={5}
                  more='Leer más'
                  less='Compactar'
                  anchorClass=''
                  // @ts-ignore
                  children={collapsableWelcome}
              />
          </p>

          <h3 className="ion-padding-top ion-padding-start">Detalles</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Ubicación
              </IonLabel>
              <IonSelect value={location} interfaceOptions={selectOptions} onIonChange={(e) => setLocation(e.detail.value as any)}>
                <IonSelectOption value="madison">Mendoza, AR</IonSelectOption>
                <IonSelectOption value="austin">Austin, TX</IonSelectOption>
                <IonSelectOption value="chicago">Chicago, IL</IonSelectOption>
                <IonSelectOption value="seattle">Seattle, WA</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>
                Fecha
              </IonLabel>
              <IonDatetime
                displayFormat="MMM DD, YYYY"
                max="2056"
                value={conferenceDate}
                onIonChange={(e) => setConferenceDate(e.detail.value as any)}>
              </IonDatetime>
            </IonItem>
          </IonList>

          <h3 className="ion-padding-top ion-padding-start">Internet</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Red Wifi (SSID)
              </IonLabel>
              <IonLabel className="ion-text-end">
                alag2019
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
               Contraseña
              </IonLabel>
              <IonLabel className="ion-text-end">
                genetica-adn
              </IonLabel>
            </IonItem>
          </IonList>

        </div>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <AboutPopover dismiss={() => setShowPopover(false)} />
      </IonPopover>
    </IonPage>
  );
};

export default React.memo(About);
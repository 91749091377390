import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonPage, IonButtons, IonMenuButton, IonTitle, IonButton, IonIcon, IonDatetime, IonSelectOption, IonList, IonItem, IonLabel, IonSelect, IonPopover } from '@ionic/react';
import './FloorPlan.scss';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import AboutPopover from '../components/AboutPopover';
import ShowMore from 'react-show-more';

interface AboutProps { }

const FloorPlan: React.FC<AboutProps> = () => {

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState();
  const [location, setLocation] = useState<'assets/img/floor-plan/planta-baja.png' | 'assets/img/floor-plan/primer-piso.png' | 'assets/img/floor-plan/segundo-piso.png'>('assets/img/floor-plan/planta-baja.png');
  const [conferenceDate, setConferenceDate] = useState('2019-10-06T00:00:00-05:00');

  const selectOptions = {
    header: 'Seleccione la planta'
  };

  const presentPopover = (e: React.MouseEvent) => {
    setPopoverEvent(e.nativeEvent);
    setShowPopover(true);
  };

  // momentjs would be a better way to do this https://momentjs.com/
  function displayDate(date: string, format: string) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const d = new Date(date);
    const year = d.getFullYear();

    if (format === 'y') {
      return year;
    } else {
      const month = monthNames[d.getMonth()];
      const day = d.getDate();

      return month + ' ' + day + ', ' + year;
    }
  }

  const collapsableWelcome = (
    <span>
      <p className="ion-padding-start ion-padding-end">
      </p>
    </span>
  );

  return (
    <IonPage id="floor-plan-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Distribución por planta</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>

        <div className="login-logo">
          <img src={location} alt={location} />
        </div>

        <div className="about-info">

          <IonList lines="none">
            <IonItem>
              <IonLabel>
                Ubicación
              </IonLabel>
              <IonSelect value={location} interfaceOptions={selectOptions} onIonChange={(e) => setLocation(e.detail.value as any)}>
                <IonSelectOption value="assets/img/floor-plan/planta-baja.png">Planta Baja</IonSelectOption>
                <IonSelectOption value="assets/img/floor-plan/primer-piso.png">Primer Piso</IonSelectOption>
                <IonSelectOption value="assets/img/floor-plan/segundo-piso.png">Segundo Piso</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonList>

        </div>
      </IonContent>

      <IonPopover
        isOpen={showPopover}
        event={popoverEvent}
        onDidDismiss={() => setShowPopover(false)}
      >
        <AboutPopover dismiss={() => setShowPopover(false)} />
      </IonPopover>
    </IonPage>
  );
};

export default React.memo(FloorPlan);
import React from 'react';

import { getMode } from '@ionic/core';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonList, IonListHeader, IonItem, IonLabel, IonCheckbox, IonFooter, IonIcon } from '@ionic/react';
import { logoAngular, call, document, logoIonic, hammer, restaurant, cog, colorPalette, construct, compass, trailSignOutline, megaphoneOutline, constructOutline, easelOutline, peopleOutline, logoElectron, bulbOutline } from 'ionicons/icons';

import './SessionListFilter.css'

import { connect } from '../data/connect';
import { updateFilteredTracks } from '../data/sessions/sessions.actions';

interface OwnProps {
  onDismissModal: () => void;
}

interface StateProps {
  allTracks: string[],
  filteredTracks: string[]
}

interface DispatchProps {
  updateFilteredTracks: typeof updateFilteredTracks;
}

type SessionListFilterProps = OwnProps & StateProps & DispatchProps;

const SessionListFilter: React.FC<SessionListFilterProps> = ({ allTracks, filteredTracks, onDismissModal, updateFilteredTracks }) => {
  const ios = getMode() === 'ios';

  const toggleTrackFilter = (track: string) => {
    if (filteredTracks.indexOf(track) > -1) {
      updateFilteredTracks(filteredTracks.filter(x => x !== track));
    } else {
      updateFilteredTracks([...filteredTracks, track]);
    }
  };

  const handleDeselectAll = () => {
    updateFilteredTracks([]);
  };

  const handleSelectAll = () => {
    updateFilteredTracks([...allTracks]);
  };

  const iconMap: { [key: string]: any } = {
    'Asamblea': peopleOutline,
    'Plenaria': logoElectron,
    'Intervalo': restaurant,
    'Ionic': logoIonic,
    'Taller': constructOutline,
    'Design': colorPalette,
    'Conferencia': megaphoneOutline,
    'Simposio': trailSignOutline,
    'Posters': easelOutline,
    'Presentacion': bulbOutline
  }

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            { ios &&
              <IonButton onClick={onDismissModal}>Cancelar</IonButton>
            }
            { !ios &&
              <IonButton onClick={handleDeselectAll}>Borrar</IonButton>
            }
          </IonButtons>

          <IonTitle>
            Filtrar charlas
          </IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={onDismissModal} strong>Aplicar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList lines={ ios ? 'inset' : 'full'}>
          <IonListHeader>Categorías</IonListHeader>

          {allTracks.map((track, index) => (
            <IonItem key={track}>
              { ios &&
                <IonIcon slot="start" icon={iconMap[track]} color="medium" />
              }
              <IonLabel>{track}</IonLabel>
              <IonCheckbox
                onClick={() => toggleTrackFilter(track)}
                checked={filteredTracks.indexOf(track) !== -1}
                color="primary"
                value={track}
              ></IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>

      { ios &&
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={handleDeselectAll}>Ninguna</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSelectAll}>Todas</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      }
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    allTracks: state.data.allTracks,
    filteredTracks: state.data.filteredTracks
  }),
  mapDispatchToProps: {
    updateFilteredTracks
  },
  component: SessionListFilter
})
